import React from "react"
import Container from 'react-bootstrap/Container'
import { Row, Col, Button, Card, Media, Table, Modal } from 'react-bootstrap'
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics } from '../components/census'

const videoList = [
  {
    linkUrl: '/strapi/uploads/AIRIOT_6b092951be.mp4',
    description: 'AIRIOT4.0产品视频',
    imageUrl: require('./../images/产品视频封面.jpeg'),
    target: 'self'
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6482f021e4b0f2aa7df66c5c',
    description: '「数字基座 智慧物联」 AIRIOT新品发布会',
    imageUrl: require('./../images/回放完整版视频封面.jpeg')
  }
]

const videoList1 = [
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e2ebe4b0b0bc2bf28db3',
    description: '无限创新|AIRIOT4.0平台发布及功能分享',
    imageUrl: require('./../images/田总.jpg')
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e413e4b0f2aa7df78b0b',
    description: '产品能力|AIRIOT4.0赋能多个行业场景落地',
    imageUrl: require('./../images/丽娜.jpg')
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e77ce4b0b2d1c4236c91',
    description: '智能制造|AIRIOT+CJ Cloud推动制造业数字化转型',
    imageUrl: require('./../images/才匠.jpg')
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e65be4b0b2d1c4236bcf',
    description: '智能环卫|AIRIOT助力构建定制化智能环卫系统',
    imageUrl: require('./../images/京环.jpg')
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e6b3e4b0cf39e6d73e17',
    description: '工业领域|AIRIOT赋能油气田及车联网行业案例',
    imageUrl: require('./../images/时空.jpg')
  },
  {
    linkUrl: 'https://appxow65u356815.pc.xiaoe-tech.com/p/t_pc/course_pc_detail/video/v_6486e5d8e4b0cf39e6d73d17',
    description: '生态合作|AIRIOT生态合作伙伴计划',
    imageUrl: require('./../images/凡哥.jpg')
  }
]

export default () => {
  const [ visible, setVisible ] = React.useState(false)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  const getCard = (item) => {
    return <Card className="base-detail recomments_card">
    <div style={{ cursor: 'pointer', position: 'relative', width: '100%', height: '17.1875rem', minHeight: '17.1875rem' }}>
      <img src={item.imageUrl} alt="" style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '17.1875rem', zIndex: 9 }}/>
      <img style={{ position: 'absolute', left: 0, top:0, right: 0, bottom: 0, margin: 'auto', zIndex: 10, width: '3.5rem', height: '3.5rem' }} src={require('./../images/WechatIMG226.png')} />
    </div>
    <Card.Body>
      <Card.Text style={{ minHeight: '2rem' }}>{item.description}</Card.Text>
    </Card.Body>
  </Card>
  }
  
  return (
    <div className="product-page about-page">
    <div className="home-bg home-index" style={{ backgroundImage:`url(${require("./../images/内页banner.png")})` }}>
      <Layout>
        <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
        <div style={{ display: 'flex' }}>
          <div className="plat-jumbotron" style={{ textAlign: 'center', margin: '0 auto', color: '#fff' }}>
            <h2 className="plat-title" style={{ fontSize: '3.125rem', color: '#fff' }}>欢迎来到【数字基座 智慧物联】AIRIOT<span style={{ margin: '0 .2rem', color: '#9bf8ee' }}>4.0</span>平台发布会</h2>
            <div style={{ fontSize: '2rem', marginTop: '1.75rem' }}>您将观看到发布会的全部内容，体验新一代物联网平台的无限创新</div>
          </div>
        </div>
      </Layout>
    </div>

    <Container>
      <Row xs={1} lg={2} md={2} style={{ marginTop: '2.875rem' }}>
        {
          videoList?.length ? videoList.map(item => {
            return (
              <Col className="recomments_base" style={{ marginBottom: '1rem' }}>
                {
                  item?.target == 'self' ?
                  <div onClick={() => { setVisible(true) }}>{ getCard(item) }</div>
                   : <Link to={item?.linkUrl} style={{ textDecoration: 'none' }} target={`_black`}>
                    {getCard(item)}
                </Link>
                }
             
              </Col>
            )
          }) : null
        }
      </Row>
      <Row xs={1} lg={3} md={3} style={{ marginTop: '1.875rem' }}>
        {
          videoList1?.length ? videoList1.map(item => {
            return (
              <Col className="recomments_base" style={{ marginBottom: '1rem' }}>
                <Link to={item?.linkUrl} style={{ textDecoration: 'none' }} target={`_black`}>
                  <Card className="base-detail recomments_card">
                    <div style={{ cursor: 'pointer', position: 'relative', width: '100%', height: '14rem', minHeight: '14rem' }}>
                      <img src={item.imageUrl} alt="" style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '14rem', zIndex: 9 }} />
                      <img style={{ position: 'absolute', left: 0, top:0, right: 0, bottom: 0, margin: 'auto', zIndex: 10, width: '3.5rem', height: '3.5rem' }} src={require('./../images/WechatIMG226.png')} />
                    </div>
                    <Card.Body>
                      <Card.Text style={{ minHeight: '3.125rem' }}>{item.description}</Card.Text>
                    </Card.Body>
                  </Card>
                </Link>
              </Col>
            )
          }) : null
        }
      </Row>
      <Modal className="video-modal-dialog" bsSize="lg" show={visible} onHide={() => setVisible(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <video poster={require('./../images/单帧10001.png')} style={{ width: '100%', height: '100%', background: '#fff' }}
              src='/strapi/uploads/AIRIOT_6b092951be.mp4' controls autoPlay="autoplay" muted={true} loop
            />
          </Modal.Body>
        </Modal>
    </Container> 
    <Footer style={{ marginTop: '120px' }}></Footer>
  </div>   
  )
}